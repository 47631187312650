import {makeSetters} from '@helpers/vuex/mutations'
import {smarterGet, smarterPost} from '@helpers/vuex/data-loading'

import moment from 'moment'

const defaults = () => ({
  data: {
    satelliteImages: [],
    ignoredImageCount: 0,
    selectedImageId: null,
    borderGeometry: null
  },
  ui: {
    calculationStarted: false,
    startDate: moment().subtract(1, 'month').startOf('d'),
    buffer: 10,
    useRedBorder: true
  }
})

export default {
  namespaced: true,
  state: defaults(),
  getters: {
    selectedImage (state) {
      if (!state.data.selectedImageId) return null
      return state.data.satelliteImages.find(image => image.id === state.data.selectedImageId)
    }
  },
  mutations: {
    ...makeSetters([
      'data.satelliteImages',
      'data.borderGeometry',
      'data.ignoredImageCount',
      'ui.calculationStarted',
      'ui.startDate',
      'ui.buffer',
      'ui.useRedBorder'
    ]),
    setSatelliteImages (state, images) {
      images.forEach(image => {
        // for testing: fix dto format
        image.recordingDate = image.recordingDate.substring(0, 10)
        // for testing: create surrogate id for UI
        image.id = `${image.recordingDate}-${image.dataSource}`
      })

      state.data.satelliteImages = images
    },
    setDefaultState (state) {
      Object.assign(state, defaults())
    },
    setSelectedImageId (state, id) {
      state.data.selectedImageId = state.data.selectedImageId !== id
        ? id
        : null
      state.ui.calculationStarted = false
    },
    resetSelectedImageId (state) {
      state.data.selectedImageId = null
      state.ui.calculationStarted = false
    }
  },
  actions: {
    loadBorderGeometry ({commit, rootState, rootGetters}) {
      return smarterGet('/api/v2/entities/fields/{fieldId}/geodata/{harvestYear}', {
        id: 'biomassMaps.borderGeometry',
        expiry: 0, // NOTE does not work properly with high expiration because store location is unloaded on module exit
        inputs: {
          harvestYear: () => rootState.fieldRecordSystem.userSettings.harvestYear,
          fieldId: () => rootGetters['fieldRecordSystem/fieldId']
        },
        onResult (geometry) {
          commit('setBorderGeometry', geometry)
        }
      })
    },
    loadSatelliteImages ({commit, rootGetters, state}) {
      state.ui.selectedImageId = null

      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']
      const startDate = moment(state.ui.startDate).format('YYYY-MM-DD')

      return smarterGet(`/api/v2/biomass/entities/fields/${fieldId}/biomass-map/${harvestYear}/images/${startDate}`, {
        id: 'get.ndviPreview',
        expiry: 5,
        inputs: {
          fieldId: () => fieldId,
          harvestYear: () => harvestYear,
          startDate: () => startDate
        },
        sharedCache: true,
        onResult ({images, ignoredImageCount}) {
          commit('resetSelectedImageId')
          commit('setIgnoredImageCount', ignoredImageCount)
          commit('setSatelliteImages', images)
        }
      })
    },
    async loadPrerequisiteData ({commit, dispatch}) {
      commit('setDefaultState')

      await Promise.all([
        dispatch('loadSatelliteImages'),
        dispatch('loadBorderGeometry')
      ])
    },
    calculateBiomassMap ({rootGetters, rootState, state}) {
      const fieldId = rootGetters['fieldRecordSystem/fieldId']
      const harvestYear = rootGetters['fieldRecordSystem/harvestYear']

      const biomassData = state.data.satelliteImages.find(image => image.id === state.data.selectedImageId)

      const {cloudMean, recordingDate: imageDate, ndviMean} = biomassData

      const dto = {
        fieldId,
        harvestYear,
        geoJsonGeometry: state.data.borderGeometry,
        cloudMean,
        imageDate,
        ndviMean,
        buffer: state.ui.useRedBorder ? state.ui.buffer : null
      }

      return smarterPost('/api/v2/biomass/create', dto, {
        id: 'frs.zoneMaps.sentinel.createZoneMap'
      })
    }
  }
}
